@import '_variables.scss';

.page-title-section {
  padding-bottom: 2em;

  .controls {
    display: grid;
    grid-template-columns: 7fr auto;
    justify-content: center;
    align-items: baseline;
    grid-gap: 6em;

    .search-container {
      font-size: 1rem;

      .search-icon {
        color: $heather;
      }

      rea-input {
        width: 100%;
      }
    }

    .add-container {
      display: flex;
      justify-content: center;
    }
  }
}

.no-data-message {
  font-size: 1.2rem;
  margin-top: 1em;
}

.card-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2em;
  padding-bottom: 2em;

  a {
    color: $oxford-blue;

    &:hover {
      color: $primary;
      transition: color 300ms ease-in;
    }
  }


  .environment-modified .mat-card {
    background: rgba($hoki, 0.1);
  }

  .expand-button {
    background-color: $white-smoke;
    border-radius: 50px;
    height: 36px;
    width: 36px;
    position: absolute;
    bottom: -19px;
    margin-left: 40%;
    display: none;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 5%), 0px 2px 6px 0px rgb(0 0 0 / 5%), 0px 1px 2px rgb(0 0 0 / 5%);

    & .open{
      transform: rotate(180deg);
    }
  }

  .expand-card {
    grid-row: 2 span;
  }

  .list-summary.card-wrapper.expand-card .mat-card:hover {
    background-color: white !important;
  }

  .card-label {
    background-color: #CAEBD5;
    border-radius: 6px;
    padding: 2px 9px;
    font-size: 0.7rem;
    white-space: nowrap;
  }

  .card-wrapper {
    transition: background-color 700ms ease-in;

    &.attention-card .mat-card {
      border-left: 4px solid $danger;
    }

    &.file-list {
      .mat-card-actions, .mat-card-subtitle, .mat-card-content {
        margin-bottom: 0;
      }

      .mat-card:hover {
        .expand-button {
          display: flex;

          mat-icon {
            display: flex;
          }
        }
      }

      .card-content {
        margin: 8px 0;
      }
    }

    .card-layout {
      display: grid;
      grid-template-columns: minmax(0, 1fr) auto;
      grid-gap: 1em;
      align-items: baseline;
      font-weight: 500;
      font-size: 1.5em;

      .event-card {
        grid-template-columns: none;
        grid-gap: 0.5em;
        display: grid;
      }

      .card-content {
        font-size: .8rem;
        grid-column: span 2;
      }

      sup {
        visibility: hidden;

        &.changes {
          visibility: visible;
        }
      }

      .pending-changes {
        height: 10px;
        width: 10px;
        background: $accent;
        border-radius: 50%;
        position: absolute;
        margin-left: 10px;

        &::after {
          visibility: hidden;
          content: 'pending changes';
          font-size: 0.6rem;
          color: white;
          text-transform: uppercase;
          background: $accent;
          position: absolute;
          top: 0;
          width: max-content;
          line-height: initial;
          padding: 2px;
        }
      }

      .title:hover {
        .changes .pending-changes {
          &::after {
            visibility: visible;
            transition: visibility 300ms ease-in-out;
            transition-delay: 300ms;
          }
        }


        .pending-changes.unpublished {
          content: 'unpublished';
        }
      }

      .missing-file {
        color: $hoki;

        &:hover {
          color: $primary;
        }
      }

      .subtitle {
        font-size: 0.8rem;
      }

      .highlight-content {
        color: $hoki;
      }

      .manage {
        margin: auto;

        .mat-icon {
          color: $hoki;
          transition: color 300ms;
        }

        &:hover .mat-icon {
          transform: scaleX(-1);
          transition: transform ease-in-out, color ease-in;
          transition-delay: 400ms;
          color: $primary;
        }
      }

      .preview-btn {
        color: $hoki;

        &.deactivate {
          color: $gainsboro;

          &:hover {
            cursor: initial;
            color: $gainsboro;
          }
        }

        &:hover {
          color: $primary;
        }
      }
    }
  }
}

.event-list {
  .mat-card .mat-card-content {
    margin-bottom: 10px;
  }
}

.list-controls {
  display: grid;
  grid-template-columns: 5fr 1fr;
  justify-content: center;
  align-items: baseline;
  grid-gap: 2em;

  rea-button {
    padding-right: 1em;
  }
}

.mat-paginator {
  background: unset !important;

  .mat-paginator-container {
    min-height: unset;
  }
}

.list-card-title {
  display: grid;
  grid-template-columns: minmax(0, min-content) min-content
}


@media (width <= $breakpoint-xxl) {
  .card-list {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (width <= $breakpoint-xl) {
  .card-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width <= $breakpoint-lg) {
  .card-list {
    grid-template-columns: 1fr 1fr;
  }
}

@media (width <= $breakpoint-sm) {
  .card-list {
    grid-template-columns: 1fr;
    padding: 1em 0;

    .card-wrapper {
      max-width: 92vw;
      min-width: 92vw;
    }
  }

  .page-title-section .controls {
    grid-gap: 1em;


    .mat-form-field-infix {
      width: auto;
    }
  }

  .list-controls {
    grid-template-columns: auto 1fr;
    padding: 0;
  }
}
